import type { Product, ProductVariant } from '~/types/models/product'
import { getVariantFromSku } from '~/utils/products/getVariantFromSku'

export function useBuyWithPrimeWidget() {
  const {
    public: { BwpScriptUrl },
  } = useRuntimeConfig()
  const gtm = useGTM()

  function onLoaded() {}

  const { load } = useScriptTag(BwpScriptUrl, onLoaded, { async: true, manual: true })

  const pushAddToCartToGtm = (product: Product) => {
    const variant = getVariantFromSku(window.bwp.sku, product)
    gtm.pushAddToCart({ item: product, variant, source: 'productPage', isBwp: true })
  }

  const updateBwpVariant = (variant: ProductVariant, isQuickShop: boolean, product: Product) => {
    const storefrontStore = useStorefrontStore()
    const { currentStorefrontCode } = storeToRefs(storefrontStore)

    if (currentStorefrontCode.value === 'us'
      && !isQuickShop
      && product.categories.includes('buy-with-prime')) {
      window.bwp.updateWidget(variant.sku)
    }
  }

  const loadScript = (product: Product) => {
    load()
    if (product) {
      window.removeEventListener('bwp-add-to-cart', () => {
        pushAddToCartToGtm(product)
      })
      window.addEventListener('bwp-add-to-cart', () => {
        pushAddToCartToGtm(product)
      })
    }
  }

  return {
    loadScript,
    updateBwpVariant,
  }
}
